import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { BaseLayout } from './layouts';
import { CheckAvailability, NotFound } from './containers';
import './App.css';

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const hasParams = urlParams.has('data');

  return <div className="App">
    <Routes>
      <Route path="/" element={<BaseLayout />}>
      <Route index element={hasParams ? <CheckAvailability /> : <NotFound />} />
      <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  </div>;
}

export default App;
