import Axios from 'axios';

import { Address, ResultCode } from '../types';

const BASE_URL = process.env.REACT_APP_API_BASE_URL as string;

const axios = Axios.create();

const getAddressesByPostcodeUrl = (postcode: string) => `${BASE_URL}/address/lookup/postcode/${postcode}`;
const getAvailabilityByUprnUrl = (uprn: string) => `${BASE_URL}/address/availability/uprn/${uprn}`;

const postInterestRegisterUrl = () => `${BASE_URL}/ryi/interest/register`;

interface GetAddressResponse {
  data: Array<Address>;
}

interface GetAvailabilityResponse {
  data: {
    RFS: ResultCode;
    qbvsState: boolean;
  };
}

export async function getAddressesByPostcode(postcode: string) {
  const response = await axios.get<GetAddressResponse>(getAddressesByPostcodeUrl(postcode));

  return response.data.data;
}

export async function getAvailabilityByUprn(uprn: string) {
  const response = await axios.get<GetAvailabilityResponse>(getAvailabilityByUprnUrl(uprn));

  return response.data.data;
}

export async function postInterestRegister(values: any) {
  const response = await axios.post<GetAvailabilityResponse>(postInterestRegisterUrl(), values);

  return response;
}
