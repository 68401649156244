/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';

import { getAvailabilityByUprn, postInterestRegister } from '../../api';

import RYIForm from './Forms/RYIForm';

import { FormTypes, ResultCode } from '../../types';

import stylesheet from './CheckAvailability.module.scss';
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';
import { SocialButtons } from './SocialButtons/SocialButtons';
import Loader from '../../loader/Loader';
import { CompleteMessageLinks } from './CompleteMessageLinks/CompleteMessageLinks';
import { notification } from 'antd';
import { useData } from '../../context/DataContext';

export default function CheckAvailability() {
  const urlParams = new URLSearchParams(window.location.search);
  const { t } = useTranslation();
  const encodedData = urlParams.get('data') as string;
  const decodedDataFromUrl = decodeURIComponent(encodedData);
  const decryptedData = CryptoJS.AES.decrypt(decodedDataFromUrl, 'addressAndPostcode');
  const serializedData = decryptedData.toString(CryptoJS.enc.Utf8);
  const dataObject = JSON.parse(serializedData);
  let address = '';
  let postcode = '';
  const partsOfAddressString = dataObject.postCodeAndAddress.split(',');
  postcode = partsOfAddressString.pop().trim();
  address = partsOfAddressString.join(',').trim();
  const [resultCode, setResultCode] = useState<ResultCode>();
  const [eligible, setEligible] = useState(false);
  const [uprnError, setUprnError] = useState(false);
  const [timeoutError, setTimeoutError] = useState(false);
  const [newsletterSubscription, setNewsletterSubscription] = useState<boolean>(false);
  const [gigabitVoucher, setGigabitVoucher] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showCompletedMessage, setShowCompletedMessage] = useState(false);

  const messageRef = useRef<HTMLHeadingElement | null>(null);
  const [api, contextHolder] = notification.useNotification();
  const { setSharedIsFormSubmited } = useData();

  useEffect(() => {
    if (!dataObject.manual) {
      setShowLoader(true);
      getAvailabilityByUprn(dataObject.uprn).then((data) => {
        console.info('# getAvailabilityByUprn', data);
        setResultCode(data.RFS);
        setEligible(data.qbvsState);
      }).catch((error) => {
        console.error('GetAvailabilityByUprn error', error);
        if (error.response &&
           (error.response.status === 504 || error.response.status === 408)
        ) {
          setTimeoutError(true);
        } else {
          setUprnError(true);
        }
      }).finally(() => {
        setShowLoader(false);
        setShowForm(true);
      });
    } else {
      setShowForm(true);
    }
  }, []);

  const handleRYISubmit = (values: any) => {
    setShowLoader(true);
    const requestValues = {
      ...values,
      manualAddress: dataObject.manual,
      rfs: resultCode ?? undefined,
      uprn: dataObject.uprn.includes('undefined') ? undefined : dataObject.uprn,
      dcms: eligible ?? undefined,
      customerType: dataObject.dropdown.toLowerCase(),
      gigabitVoucher,
      newsletterSubscription
    };
    console.info('SubmitRYIForm', requestValues);
    postInterestRegister(requestValues).then((data) => {
      setShowCompletedMessage(true);
      setSharedIsFormSubmited(true);
    }).catch((error) => {
      setSharedIsFormSubmited(false);
      console.log(error, 'error');
      api.error({
        message: t('requestError.title'),
        description: error.response.data.error.displayMessage || error.response.data.error.message || t('requestError.description'),
        placement: 'topRight',
        duration: 10,
      });
      console.error('# postInterestRegisterError', error);
    }).finally(() => {
      setShowLoader(false);
    });
  };

  const renderCompletedMessage = () => {
    if (dataObject.manual) {
      return (
        <>
          <h3 className={stylesheet.title} ref={messageRef}>{t('checkAvailability.completeMessageMainTitle')}</h3>
          <p className={stylesheet.completeText}>{t('checkAvailability.completeMessageMainDescription1')}</p>
          <p className={stylesheet.completeText}>
            {t('checkAvailability.completeMessageManualTitleDescription2')}
          </p>
        </>
      );
    }

    if (uprnError) {
      return <>
            <h3 className={stylesheet.title} ref={messageRef}>{t('checkAvailability.completeMessage4Title')}</h3>
            <p className={stylesheet.completeText}>{t('checkAvailability.completeMessage4Description1')}</p>
            <p className={stylesheet.completeText}>
                {dataObject.dropdown === FormTypes.residential
                  ? t('checkAvailability.completeMessage4Description2')
                  : t('checkAvailability.completeMessage4Description2Business')}
            </p>
        <SocialButtons isEmailIcon={true} />
        </>;
    }

    switch (resultCode) {
      case 'RFS_1':
      case 'RFS_2':
      case 'RFS_3':
      case 'RFS_4': {
        return (
          <>
            <h3 className={stylesheet.title} ref={messageRef}>{t('checkAvailability.completeMessageMainTitle')}</h3>
            <p className={stylesheet.completeText}>{dataObject.dropdown === FormTypes.residential
              ? t('checkAvailability.completeMessageMainDescription1')
              : t('checkAvailability.completeMessageMainDescription1Business')}</p>
            <p className={stylesheet.completeText}>
              {dataObject.dropdown === FormTypes.residential
                ? t('checkAvailability.completeMessage2Description1')
                : t('checkAvailability.completeMessage2Description3')}
            </p>
            <p className={stylesheet.completeText}>{t('checkAvailability.completeMessage3Description2')}</p>
            <SocialButtons/>
          </>
        );
      }
      case 'NOT_RFS_1':
      case 'RFS_0':
      case 'NOT_RFS_2': {
        return (
          <>
            <h3 className={stylesheet.title} ref={messageRef}>{t('checkAvailability.completeMessageMainTitle')}</h3>
              {dataObject.dropdown === FormTypes.residential
                ? <p className={stylesheet.completeText}>{t('checkAvailability.completeMessageMainDescription1')}</p>
                : <p className={stylesheet.completeText}>{t('checkAvailability.completeMessageMainDescription1Business')}</p>}

            <p className={stylesheet.completeText}>
                {t('checkAvailability.completeMessage6Description1')}
            </p>
            <p className={stylesheet.completeText}>{t('checkAvailability.completeMessage6Description2')}</p>
            <SocialButtons/>
          </>
        );
      }
      case 'NOT_RFS_0':
      case 'NOT_RFS_4': {
        return (
          <>
            <h3 className={stylesheet.title} ref={messageRef}>{t('checkAvailability.completeMessage4Title')}</h3>
              {dataObject.dropdown === FormTypes.residential
                ? <>
                      <p className={stylesheet.completeText}>
                          {t('checkAvailability.completeMessage4Description1')}
                      </p>
                      <p className={stylesheet.completeText}>
                          {t('checkAvailability.completeMessage4Description2')}
                      </p>
                  </>

                : <>
                      <p className={stylesheet.completeText}>
                          {t('checkAvailability.completeMessage4Description1Business')}
                      </p>
                      <p className={stylesheet.completeText}>
                          {t('checkAvailability.completeMessage4Description2Business')}
                      </p>
                  </>
              }
              <SocialButtons isEmailIcon={true} />
          </>
        );
      }

      case 'NOT_RFS_3': {
        return (
                <>
                    <h3 className={stylesheet.title} ref={messageRef}>{t('checkAvailability.completeMessage5Title')}</h3>
                    <p className={stylesheet.completeText}>
                        {t('checkAvailability.completeMessage5Description1')}
                        <a href="https://www.freedomfibre.com/contact" target="_blank" rel="noreferrer">{t('checkAvailability.completeMessage5Description1Link')}</a>
                    </p>
                    <p className={stylesheet.completeText}>
                        {t('checkAvailability.completeMessage5Description2')}
                        <a href="https://www.freedomfibre.com/wayleave" target="_blank" rel="noreferrer">{t('checkAvailability.completeMessage5Description2Link')}</a>
                        {t('checkAvailability.completeMessage5Description3')}
                        <span>{t('checkAvailability.completeMessage5DescriptionEmail')}</span>
                        {t('checkAvailability.completeMessage5Description4')}
                    </p>
                    <SocialButtons/>
                </>
        );
      }
    }
  };

  const renderCompleteMessageWithLinks = () => {
    return (
      <>
        {renderCompletedMessage()}
        <CompleteMessageLinks />
      </>
    );
  };

  useEffect(() => {
    if (showCompletedMessage) {
      messageRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }, [showCompletedMessage, messageRef]);

  return (
    <div className={`${stylesheet.root} ${showCompletedMessage ? stylesheet.rootRYI : ''}`}>
      {contextHolder}
      <Loader show={showLoader} />
      {timeoutError
        ? (<h3 className={stylesheet.errorText}>{t('timeoutError.text')}</h3>)
        : (showCompletedMessage
            ? renderCompleteMessageWithLinks()
            : showForm && <RYIForm
                      eligible={eligible}
                      error={uprnError}
                      formType={dataObject.dropdown}
                      postcode={postcode}
                      address={address}
                      manual={dataObject.manual}
                      setGigabitVoucher={setGigabitVoucher}
                      setNewsletterSubscription={setNewsletterSubscription}
                      resultCode={resultCode}
                      onSubmit={handleRYISubmit}
           />)}
    </div>
  );
}
