import React, { createContext, useContext, useState, ReactNode } from 'react';

interface DataContextProps {
  isFormSubmited: boolean;
  setSharedIsFormSubmited: (isFilled: boolean) => void;
}

const DataContext = createContext<DataContextProps | undefined>(undefined);

interface DataProviderProps {
  children: ReactNode;
}

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
  const [isFormSubmited, setIsFormSubmited] = useState<boolean>(false);

  const setSharedIsFormSubmited = (isSubmited: boolean) => {
    setIsFormSubmited(isSubmited);
  };

  return (
    <DataContext.Provider value={{ isFormSubmited, setSharedIsFormSubmited }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = (): DataContextProps => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};
