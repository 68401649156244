import React from 'react';

import stylesheet from './Footer.module.scss';
import {
  getFooterMainLinks,
} from '../../utils/constants';

interface LinkInterface {
  href: string;
  text: string;
}

export default function Footer() {
  return (
      <footer className={stylesheet.footer}>
      <div className={stylesheet.linksContainer}>
      {getFooterMainLinks().map((item: LinkInterface, index: number) => {
        return (
                  <a
                      href={item.href}
                      rel="noreferrer"
                      target="_blank"
                      key={index}
                  >
                    {item.text}
                  </a>
        );
      })}
        </div>
      </footer>
  );
}
