import { Form, Input } from 'antd';
import stylesheet from './RYIForm.module.scss';
import React from 'react';

export function FormInputItem({
  required,
  name,
  label,
  rules,
  inputType,
}: {
  required?: boolean;
  name: string;
  label: string;
  rules?: any;
  inputType?: string;
}) {
  return (
        <Form.Item
            className={stylesheet.item}
            required={required}
            name={name}
            label={label}
            rules={rules}
        >
            <Input type={inputType} className={stylesheet.input} />
        </Form.Item>
  );
}
