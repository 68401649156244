// i18n.js

import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en.json';

i18n.use(initReactI18next).init({
  lng: 'en', // Default language (English)
  resources: {
    en: {
      translation: translationEN // Add translations here if needed
    }
  },
  interpolation: {
    escapeValue: false // React already escapes variables, so no need to escape them again
  }
});

export default i18n;
