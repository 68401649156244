import { useTranslation } from 'react-i18next';
import stylesheet from '../../src/containers/CheckAvailability/CompleteMessageLinks/CompleteMessageLinks.module.scss';

export const EMAIL_REGEX = /^([a-zA-Z0-9_+\-.]+)@([a-zA-Z0-9_-]+)(\.[a-zA-Z]{2,5}){1,2}$/gm;

const linkConfig = {
  header: [
    {
      text: 'header.headerNavLink',
      href: 'https://www.freedomfibre.com/'
    }
  ],
  footerMainLinks: [
    {
      text: 'footer.links.privacyPolicy',
      href: 'https://www.freedomfibre.com/privacy-policy',
    },
    {
      text: 'footer.links.cookiesPolicy',
      href: 'https://www.freedomfibre.com/cookies-policy',
    },
    {
      text: 'footer.links.acceptableUsePolicy',
      href: 'https://www.freedomfibre.com/acceptable-use',
    },
    {
      text: 'footer.links.termsOfUse',
      href: 'https://www.freedomfibre.com/terms-of-use',
    },
    {
      text: 'footer.links.contactUs',
      href: 'https://www.freedomfibre.com/contact',
    },
  ],
};

const generateLinks = (linkKeys: any) => {
  const { t } = useTranslation();

  return linkKeys.map((linkKey: any) => {
    const { text, ...rest } = linkKey;
    return { text: t(text), ...rest };
  });
};

export const getHeaderItems = () => generateLinks(linkConfig.header);

export const getFooterMainLinks = () =>
  generateLinks(linkConfig.footerMainLinks);

export const whereDidYouHearAboutUsBusinessOptions = [
  { label: 'Letter / Leaflet', value: 'letter-or-leaflet' },
  { label: 'Email', value: 'email' },
  { label: 'Social media', value: 'social-media' },
  { label: 'Word of mouth', value: 'word-of-mouth' },
  { label: 'Newspaper / Magazine', value: 'newspaper-or-magazine' },
  { label: 'Digital advert / Radio', value: 'digital-adverb-or-radio' },
  { label: 'Outdoor advert', value: 'outdoor-advert' },
  { label: 'Other', value: 'other' },
];

export const whereDidYouHearAboutUsResidentialOptions =
    // without email
    [...whereDidYouHearAboutUsBusinessOptions.filter(option => option.value !== 'email'),
      { label: 'Search engine', value: 'search-engine' },
      { label: 'Local council', value: 'local-council' },
    ];

export const propertyRelationshipOptons = [
  { label: 'Owner', value: 'owner' },
  { label: 'Tenant', value: 'tenant' },
  { label: 'Landlord', value: 'landlord' },
];

export const remainingTimeOnCurrentOptions = [
  { label: 'Less than 1 month', value: '<1' },
  { label: '1-3 months', value: '1-3' },
  { label: '3-12 months', value: '3-12' },
  { label: 'More than 12 months', value: '12+' },

];

export const companySizeOptions = [
  { label: 'Less than 10', value: '<10' },
  { label: '10-99', value: '10-99' },
  { label: '100+', value: '100+' },
];

export const EMAIL_SUBJECT = 'Are you looking for reliable, gigabit-capable connectivity in your home or business?';

export const EMAIL_BODY = 'Join me and register with Freedom Fibre to show our interest in their full-fibre broadband network. \n The more registrations they receive from our area, the more likely they are to build to us! \n Register your interest now using their availability checker https://www.freedomfibre.com/connect. \n \n Facebook: https://www.facebook.com/freedomfibreuk \n X: https://twitter.com/FreedomFibre \n Instagram: https://www.instagram.com/freedomfibre/ \n LinkedIn: https://www.linkedin.com/company/freedomfibre';

export const completeMessageLinks = [
  {
    imgClass: stylesheet.faqGeneral,
    title: 'Frequently asked questions',
    text: 'If you\'re new to lightning-fast FTTP broadband technology and its benefits, our frequently asked questions page offers useful information on Freedom Fibre\'s service and the way we work so you\'ll know what to expect along the way.',
    link: 'https://www.freedomfibre.com/faq-general'
  },
  {
    imgClass: stylesheet.faqTelegraph,
    title: 'FAQ – telegraph poles',
    text: 'If you have seen a pole notification, go up on your street and/or want to learn more about the installation of new telegraph poles to deliver full-fibre broadband, you can find information here.',
    link: 'https://www.freedomfibre.com/faq-poles'
  },
  {
    imgClass: stylesheet.faqLandlords,
    title: 'FAQ – property owners &\nlandlords',
    text: 'We need a wayleave agreement when we need to access private land. It allows us to install, use, keep, substitute, repair, maintain, upgrade, and replace our equipment. You can learn more about this process here.',
    link: 'https://www.freedomfibre.com/faq-property-owners-landlords'
  },
  {
    imgClass: stylesheet.faqTenants,
    title: 'FAQ – information for tenants',
    text: 'In the case of a tenanted property, your landlord will need to be party to a wayleave agreement. You can learn more about this process for tenants and how you can help assist the progress of your full-fibre installation here.',
    link: 'https://www.freedomfibre.com/faq-tenants'
  },
  {
    imgClass: stylesheet.installation,
    title: 'Installation:\nwhat to expect',
    text: 'Our new full-fibre network connects directly to your property. To make this connection, we’ll need to send one of our friendly engineers to your property. Learn more about how we work, so you know what to expect.',
    link: 'https://www.freedomfibre.com/installation'
  },
  {
    imgClass: stylesheet.voucher,
    title: 'Gigabit Voucher Scheme',
    text: 'Full-fibre network builds are supported by the Department of Science, Innovation & Technology (DSIT). Their UK Gigabit Voucher Scheme helps fund some of the costs of bringing gigabit-capable broadband to hard-to-reach areas.',
    link: 'https://www.freedomfibre.com/gigabit-voucher-scheme'
  },
  {
    imgClass: stylesheet.copperNetwork,
    title: 'Copper network retirement',
    text: 'Our new network is set to replace the old copper networks to bring you much faster speeds, greater connectivity, and increased reliability. This new technology also ensures you\'ll be ready for the future digital world as old services are retired.',
    link: 'https://www.freedomfibre.com/copper-network-retirement'
  },
];
