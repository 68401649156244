import React from 'react';
import { Outlet } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';

import stylesheet from './BaseLayout.module.scss';

export default function BaseLayout() {
  return (
    <section className={stylesheet.layout}>
      <Header />

      <div className={stylesheet.content}>
        <Outlet />
      </div>

      <Footer />
    </section>
  );
}
