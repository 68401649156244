import React from 'react';
import stylesheet from './SocialButtons.module.scss';
import { EMAIL_BODY, EMAIL_SUBJECT } from '../../../utils/constants';

interface Props {
  isEmailIcon?: boolean;
}

export function encodeSpaces(inputString: string) {
  return inputString.replace(/ /g, '%20');
}

export function encodeNewLine(inputString: string) {
  return inputString.replace(/\n/g, '%0D%0A');
}

export function SocialButtons({ isEmailIcon }: Props) {
  const subject = encodeSpaces(EMAIL_SUBJECT);
  const bodyWithSpaces = encodeSpaces(EMAIL_BODY);
  const bodyWithNewLines = encodeNewLine(bodyWithSpaces);

  return <div className={stylesheet.socialButtons}>
      {isEmailIcon && <a
            className={stylesheet.email}
            href={`mailto:?subject=${subject}&body=${bodyWithNewLines}`}
            target="_blank"
            rel="noreferrer"
        />}
        <a
            className={stylesheet.facebook}
            href="https://www.facebook.com/freedomfibreuk"
            target="noreferrer"
        />
        <a
            className={stylesheet.twitter}
            href="https://www.twitter.com/freedomfibre"
            target="noreferrer"
        />
        <a
            className={stylesheet.linkedin}
            href="https://www.linkedin.com/company/freedomfibre"
            target="noreferrer"
        />
        <a
            className={stylesheet.instagram}
            href="https://www.instagram.com/freedomfibre"
            target="noreferrer"
        />
    </div>;
}
