/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Button, Checkbox, Form } from 'antd';

import { postcodeRegex } from '../helpers';
import { FormTypes, ResultCode } from '../../../types';

import stylesheet from './RYIForm.module.scss';
import { useTranslation } from 'react-i18next';
import {
  companySizeOptions,
  remainingTimeOnCurrentOptions,
  whereDidYouHearAboutUsBusinessOptions,
  whereDidYouHearAboutUsResidentialOptions,
  EMAIL_REGEX,
  propertyRelationshipOptons
} from '../../../utils/constants';
import { FormSelectItem } from './SelectItem';
import { FormInputItem } from './InputItem';
import { isValidNumberForRegion } from 'libphonenumber-js';
import { useForm } from 'antd/es/form/Form';

interface Props {
  eligible: boolean;
  manual?: boolean;
  resultCode?: ResultCode;
  address: string;
  postcode: string;
  formType: string;
  error?: boolean;
  onSubmit: (values: any) => void; // todo: add types
  setGigabitVoucher: (value: boolean) => void;
  setNewsletterSubscription: (value: boolean) => void;
}

export default function RYIForm({
  eligible,
  manual,
  address,
  postcode,
  resultCode,
  formType,
  error,
  onSubmit,
  setNewsletterSubscription,
  setGigabitVoucher,
}: Props) {
  const { t } = useTranslation();
  const [form] = useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const renderHeader = () => {
    if (manual) {
      return (
          <>
            <h3 className={stylesheet.messageTitle}>{t('ryiForm.manualTitle')}</h3>
            <p className={stylesheet.messageText}>
                {t('ryiForm.manualTitleDescription')}
            </p>
          </>
      );
    }

    if (error) {
      return (
              <>
                  <h3 className={stylesheet.messageTitle}>{t('ryiForm.urpnErrorTitle')}</h3>
                  <p className={stylesheet.messageText}>
                      {t('ryiForm.urpnErrorText')}
                  </p>
                  <p className={stylesheet.messageText}>
                     <b>{t('ryiForm.headerDescription4Bold')}</b>
                  </p>
                  <p className={stylesheet.messageText}>
                      {t('ryiForm.headerDescription5')}
                  </p>
                  <p className={stylesheet.messageText}>
                    {t('ryiForm.headerDescription6')}
                    <a href="https://www.gov.uk/guidance/project-gigabit-network-build-contract-north-shropshire"
                       target="_blank" rel="noreferrer">
                        {t('ryiForm.headerDescription6Link')}</a>
                    {t('ryiForm.headerDescription6End')}
                </p>
              </>
      );
    }

    switch (resultCode) {
      case 'RFS_1':
      case 'RFS_2':
      case 'RFS_3':
      case 'RFS_4':
        return (
            <>
              <h3 className={stylesheet.messageTitle}>
                  {t('ryiForm.headerTitle1')}
              </h3>
              <p className={stylesheet.messageText}>
                  {t('ryiForm.headerDescription1')}
              </p>
            </>
        );

      case 'NOT_RFS_2':
      case 'RFS_0':
        return (
            <>
              <h3 className={stylesheet.messageTitle}>
                  {t('ryiForm.headerTitle2')}
              </h3>
              <p className={stylesheet.messageText}>
                  {t('ryiForm.headerDescription2')}
              </p>
            </>
        );
      case 'NOT_RFS_1':
        return (
            <>
              <h3 className={stylesheet.messageTitle}>
                  {eligible ? t('ryiForm.headerTitle3') : t('ryiForm.headerTitle3NotEligable')}
              </h3>
              <p className={stylesheet.messageText}>
                  {t('ryiForm.headerDescription3')}
              </p>
            </>
        );
      case 'NOT_RFS_0':
      case 'NOT_RFS_4':
        return (
            <>
              <h3 className={stylesheet.messageTitle}>
                  {t('ryiForm.headerTitle4')}
              </h3>
              <p className={stylesheet.messageText}>
                  {t('ryiForm.headerDescription4')}
              </p>
                <p className={stylesheet.messageText}>
                    <b>
                        {t('ryiForm.headerDescription4Bold')}
                    </b>
                </p>
                <p className={stylesheet.messageText}>
                     {t('ryiForm.headerDescription5')}
                </p>
                <p className={stylesheet.messageText}>
                    {t('ryiForm.headerDescription6')}
                    <a href="https://www.gov.uk/guidance/project-gigabit-network-build-contract-north-shropshire"
                       target="_blank" rel="noreferrer">
                        {t('ryiForm.headerDescription6Link')}</a>
                    {t('ryiForm.headerDescription6End')}
                </p>
            </>
        );
      case 'NOT_RFS_3':
        return (
            <>
              <h3 className={stylesheet.messageTitle}>
                  {t('ryiForm.headerTitle5')}
              </h3>
              <p className={stylesheet.messageText}>
                  {t('ryiForm.headerDescription7')}
                <a
                    href='https://www.freedomfibre.com/contact'
                    target='noreferrer'
                >
                    {t('ryiForm.headerDescription5Link')}
                </a>
                .
              </p>
            </>
        );
    }
  };

  const renderFooter = () => {
    if (error) {
      return (<p className={stylesheet.agreePolicy}>
                      {t('ryiForm.agreePolicy.text1')}
                <a
                    href='https://www.freedomfibre.com/privacy-policy'
                    target='noreferrer'
                >
                    {t('ryiForm.agreePolicy.text1Link')}
                </a>
                      {t('ryiForm.agreePolicy.text2')}
       </p>
      );
    }
    if (resultCode === 'NOT_RFS_3') {
      return (<p>
                {t('ryiForm.footerText1')}
              <a
                  href='https://www.freedomfibre.com/faq-tenants'
                  target='noreferrer'
              >
                  {t('ryiForm.footerText1Link')}
              </a>
                {t('ryiForm.footerText2')}
              <a href='https://www.freedomfibre.com/wayleave' target='noreferrer'>
                  {t('ryiForm.footerText2Link')}
              </a>
                {t('ryiForm.footerText3')}
              <a href='mailto:wayleaves@freedomfibre.com'>
                  {t('ryiForm.footerText3EmailLink')}
              </a>
                  {t('ryiForm.footerText4')}
            </p>);
    }

    return (
        <>
          <p className={stylesheet.agreePolicy}>
              {t('ryiForm.agreePolicy.text1')}
            <a
                href='https://www.freedomfibre.com/privacy-policy'
                target='noreferrer'
            >
                {t('ryiForm.agreePolicy.text1Link')}
            </a>
              {t('ryiForm.agreePolicy.text2')}
          </p>

          {eligible && (
              <>
                <p className={stylesheet.eligableTitle}>
                    {t('ryiForm.eligible.text1')}
                </p>
                <p className={stylesheet.eligableText}>
                    {t('ryiForm.eligible.text2')}
                  <a
                     href='https://www.freedomfibre.com/dcms-vouchers'
                     target='noreferrer'
                  >
                     {t('ryiForm.eligible.text2Link')}
                  </a>
                  .
                </p>
                <div className={stylesheet.gigabitVoucher}>
                   <Checkbox className={stylesheet.checkbox}
                     name="gigabitVoucher"
                     onChange={(e) => {
                       setGigabitVoucher(e.target.checked);
                     }} />
                  <div>
                    <p><b>{t('ryiForm.eligible.text3Title')}</b></p>
                    <p>{t('ryiForm.eligible.text3')}</p>
                  </div>
                </div>
              </>
          )}
        </>
    );
  };

  const renderFirstNameItem = () => {
    return (
        <FormInputItem
            required
            name='firstName'
            label={t('ryiForm.fields.nameLabel')}
            rules={[{ required: true, message: t('ryiForm.fields.nameError') }]}
        />
    );
  };

  const renderCompanyNameItem = () => {
    return (
            <FormInputItem
                required
                name='companyName'
                label={t('ryiForm.fields.companyNameLabel')}
                rules={[{ required: true, message: t('ryiForm.fields.companyNameError') }]}
            />
    );
  };

  const renderLastNameItem = () => {
    return (
        <FormInputItem
            required
            name='lastName'
            label={t('ryiForm.fields.lastNameLabel')}
            rules={[{ required: true, message: t('ryiForm.fields.lastNameError') }]}
        />
    );
  };

  const renderEmailItem = () => {
    return (
        <FormInputItem
            required
            name='email'
            label={t('ryiForm.fields.emailLabel')}
            rules={[
              { required: true, message: '' },
              {
                message: t('ryiForm.fields.validationEmailErrorLabel'),
                validator: async (_: any, value: string) => {
                  if (value.match(EMAIL_REGEX)) {
                    await Promise.resolve();
                  } else {
                    await Promise.reject(t('ryiForm.fields.validationEmailErrorLabel'));
                  }
                }
              }
            ]}
        />
    );
  };

  const renderMobilePhoneItem = () => {
    return (
        <FormInputItem
            required
            name='phoneNumber'
            label={t('ryiForm.fields.phoneNumberLabel')}
            rules={[{
              required: true,
              message: ''
            },
            {
              message: t('ryiForm.fields.validationPhoneErrorLabel'),
              validator: async (_: any, value: string) => {
                const isValid = isValidNumberForRegion(value, 'GB');
                if (isValid) {
                  await Promise.resolve();
                } else {
                  await Promise.reject(t('ryiForm.fields.validationPhoneErrorLabel'));
                }
              }
            }]}
        />
    );
  };

  const renderAddressItem = () => {
    return (
        <FormInputItem
            required
            name='address'
            label={t('ryiForm.fields.addressLabel')}
            rules={[{ required: true, message: t('ryiForm.fields.addressError') }]}
        />
    );
  };

  const renderPostcodeItem = () => {
    return (
        <FormInputItem
            required
            name='postcode'
            label={t('ryiForm.fields.postcodeLabel')}
            rules={[
              { required: true, message: t('ryiForm.fields.postcodeErrorRequired') },
              {
                pattern: postcodeRegex,
                message: t('ryiForm.fields.postcodeErrorValid')
              },
            ]}
        />
    );
  };

  const renderRemainTimeOnCurrent = () => {
    return <FormSelectItem
          name='broadbandRemainingTime'
          label={t('ryiForm.fields.remainTimeOnCurrentLabel')}
          options={remainingTimeOnCurrentOptions}
      />;
  };

  const renderCompanySizeItem = () => {
    return (
        <FormSelectItem
            name='numberOfEmployees'
            label={t('ryiForm.fields.numberOfEmployeesLabel')}
            options={companySizeOptions}
        />
    );
  };

  const renderWhereYouHearAboutUs = () => {
    return (
        <FormSelectItem
            name='whereHeard'
            label={t('ryiForm.fields.whereYouHearAboutUsLabel')}
            options={formType === FormTypes.business
              ? whereDidYouHearAboutUsBusinessOptions
              : whereDidYouHearAboutUsResidentialOptions}
        />
    );
  };

  const renderPropertyRelationshipItem = () => {
    return (
        <FormSelectItem
            required
            name='propertyRelationship'
            label={t('ryiForm.fields.propertyRelationshipLabel')}
            options={propertyRelationshipOptons}
            rules={[
              { required: true, message: t('ryiForm.fields.propertyRelationshipError') },
            ]}
        />
    );
  };

  const renderResidentialFields = () => (
    <div>
        <div className={stylesheet.row}>
          {renderFirstNameItem()}
          {renderEmailItem()}
        </div>

        <div className={stylesheet.row}>
          {renderLastNameItem()}
          {renderMobilePhoneItem()}
        </div>

        <div className={stylesheet.row}>
          {renderAddressItem()}
          {renderPropertyRelationshipItem()}
        </div>

        <div className={stylesheet.row}>
          {renderPostcodeItem()}
          {renderWhereYouHearAboutUs()}
        </div>

        <div className={stylesheet.row}>
          {renderRemainTimeOnCurrent()}
          <div className={stylesheet.newsletterSubscription}>
              <Checkbox className={stylesheet.checkbox}
            name="newsletterSubscription"
                        // Reverse checked value here, since `ryiForm.agreePolicy.text3` value contains - "...tick if you would NOT like to receive..."
                        onChange={(e) => { setNewsletterSubscription(!e.target.checked); }}/>
             <p><b>{t('ryiForm.agreePolicy.text3')}</b></p>
          </div>
        </div>
      </div>
  );

  const renderBusinessFields = () => (
      <div>
        <div className={stylesheet.row}>
          {renderCompanyNameItem()}
          {renderEmailItem()}
        </div>

        <div className={stylesheet.row}>
          {renderFirstNameItem()}
          {renderMobilePhoneItem()}
        </div>

        <div className={stylesheet.row}>
            {renderLastNameItem()}
            {renderPropertyRelationshipItem()}
        </div>
        <div className={stylesheet.row}>
          {renderAddressItem()}
          {renderWhereYouHearAboutUs()}
        </div>

        <div className={stylesheet.row}>
          {renderPostcodeItem()}
          {renderCompanySizeItem()}
      </div>
      <div className={stylesheet.row}>
        <div className={`${stylesheet.newsletterSubscriptionBusiness} ${stylesheet.newsletterSubscription}`}>
              <Checkbox className={stylesheet.checkbox}
                name="newsletterSubscription"
                // Reverse checked value here, since `ryiForm.agreePolicy.text3` value contains - "...tick if you would NOT like to receive..."
                onChange={(e) => { setNewsletterSubscription(!e.target.checked); }}/>
             <p><b>{t('ryiForm.agreePolicy.text3')}</b></p>
        </div>
      </div>
      </div>
  );

  return (
      <div>
        <Form
            className={stylesheet.form}
            layout='vertical'
            initialValues={{ address, postcode }}
            onFinish={onSubmit}
            onPointerOut={() => {
              const isErrors = form.getFieldsError().some(({ errors }) => errors.length);
              const { address, companyName, email, firstName, lastName, postcode, phoneNumber, propertyRelationship } = form.getFieldsValue();
              const isValuesFilled =
              !!address &&
              !!email &&
              !!firstName &&
              !!lastName &&
              !!postcode &&
              !!phoneNumber &&
              !!propertyRelationship &&
              (formType !== FormTypes.business || !!companyName);
              setIsButtonDisabled(isErrors || !isValuesFilled);
            }}
            form={form}
        >
          {renderHeader()}
            {resultCode !== 'NOT_RFS_3' && <> <p className={stylesheet.formTitle}>{t('ryiForm.fieldsInfo')}</p>
                {formType === FormTypes.residential
                  ? renderResidentialFields()
                  : renderBusinessFields()}
                </>
            }
          {renderFooter()}
            {resultCode !== 'NOT_RFS_3' && <div className={stylesheet.row}>
                <Form.Item className={stylesheet.submitButtonItem}>
                    <Button
                        className={stylesheet.submitButton}
                        type='primary'
                        disabled={isButtonDisabled}
                        htmlType='submit'
                    >
                        {t('ryiForm.eligible.buttonText')}
                    </Button>
                </Form.Item>
            </div>}
        </Form>
      </div>
  );
}
