import React from 'react';

import stylesheet from './Header.module.scss';
import { getHeaderItems } from '../../utils/constants';
import { useData } from '../../context/DataContext';

interface HeaderItem {
  active?: boolean;
  href: string;
  text: string;
}

export default function Header() {
  const { isFormSubmited } = useData();
  const target = isFormSubmited ? '_self' : '_blank';

  return (
      <header className={stylesheet.header}>
        <a href='https://www.freedomfibre.com/' target={target}>
          <div className={stylesheet.logo} />
        </a>

        <div className={stylesheet.navigation}>
          <div className={stylesheet.navigationButtons}>
            {getHeaderItems().map((item: HeaderItem, index: number) => {
              return (
                  <a
                      className={item.active ? stylesheet.active : stylesheet.button}
                      href={item.href}
                      rel="noreferrer"
                      target={target}
                      key={index}
                  >
                    {item.text}
                  </a>
              );
            })}
          </div>
        </div>
      </header>
  );
}
