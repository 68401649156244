import React from 'react';
import stylesheet from './CompleteMessageLinks.module.scss';
import { CompleteMessageItem } from './CompleteMessageItem';
import { completeMessageLinks } from '../../../utils/constants';

export function CompleteMessageLinks() {
  return (<div className={stylesheet.container}>
      <div className={stylesheet.content}>
      {completeMessageLinks.map((item, index) =>
        <CompleteMessageItem
          key={index}
          title={item.title}
          text={item.text}
          link={item.link}
          imgClass={item.imgClass} />)}
      </div>
    </div>);
}
