export type ResultCode =
  'RFS_0'
  | 'RFS_1'
  | 'RFS_2'
  | 'RFS_3'
  | 'RFS_4'
  | 'NOT_RFS_0'
  | 'NOT_RFS_1'
  | 'NOT_RFS_2'
  | 'NOT_RFS_3'
  | 'NOT_RFS_4';

export interface Address {
  address: string;
  uprn: string;
}

export enum FormTypes {
  residential = 'Residential',
  business = 'Business'
}
