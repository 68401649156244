import React from 'react';

import stylesheet from './Loader.module.scss';

interface LoaderProps {
  show: boolean;
}
export default function Loader({ show }: LoaderProps) {
  if (show) {
    return (
    <div className={stylesheet.overlay}>
      <div className={stylesheet.overlayInner}>
        <div className={stylesheet.overlayContent}><span className={stylesheet.spinner}></span></div>
      </div>
    </div>
    );
  }
  return null;
}
