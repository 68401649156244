import React from 'react';
import stylesheet from './CompleteMessageLinks.module.scss';
import { Button } from 'antd';

interface Props {
  title: string;
  text: string;
  link: string;
  imgClass: string;
}

export function CompleteMessageItem({ title, text, link, imgClass }: Props) {
  return (<div className={stylesheet.item}>
      <div>
         <div className={imgClass} />
         <h4 className={stylesheet.itemTitle}>{title}</h4>
         <p className={stylesheet.itemText}>{text}</p>
      </div>
      <Button className={stylesheet.button} href={link}>Learn more</Button>
    </div>);
}
